<template>
    <!--
    Страница запроса кода подтверждения
    -->
    <div class="wrapper">
        <div class="form-part">
            <div class="logo-part" v-if="$vssWidth > 668 && $vssHeight > 415">
                <img class="logo" alt="logo" src="../assets/logo-lg.png">
            </div>
            <div class="form-wrapper">

                <input name="email" type="email" placeholder="Введите Email или номер телефона (+7ХХХХХХХХХ)" v-model="email">

                <input type="submit" value="Получить пароль для входа в систему" @click="onSubmit" :disabled="!valid">

                <div class="descr">
                    В течение одной минуты на вашу электронную почту или номер телефона,
                    зарегистрированные в системе, придет восьмизначный код
                    для одноразового входа в систему.
                </div>

            </div>
        </div>
        <div class="footer_links_wrapper">
            <router-link :to="{name: 'PrivacyPolicy'}" class="footer_link">
                Политика конфиденциальности
            </router-link>
            <router-link :to="{name: 'AboutCookie'}" class="footer_link">
                О cookie
            </router-link>
            <a href="https://jl-ds.ru" target="_blank" class="footer_link">
                Правообладатель
            </a>

        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
import VueScreenSize from "vue-screen-size";

export default {
    mixins: [VueScreenSize.VueScreenSizeMixin],
    name: 'Home',
    components: {},

    data: () => ({
        email: process.env.VUE_APP_DEFAULT_MAIL
    }),

    computed: {
        valid() {
            return !!this.email
        }
    },

    methods: {
        // Accessing actions
        ...mapActions("login", ["get_code"]),

        onSubmit() {
            this.get_code(this.email)
                .then(() => {
                    this.$router.push({name: 'login'})
                })
        }
    },
}
</script>

<style scoped lang="scss">

@import "../theme";

.footer_links_wrapper {
    height: 50px;
    display: flex;
    flex-direction: row;
    align-content: space-around;
    justify-content: flex-end;
    align-items: center;

    .footer_link {
        margin: 0 7px;
        color: $dom-gray;
        text-decoration: none;
    }

    .footer_link:hover {
        text-decoration: underline;
    }
}

.wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    overflow: auto;
    flex-direction: column;

    background-image: url("../assets/background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.logo-part {
    align-items: center;
    margin-top: -100px;

    img {
        display: block;
        margin: 0 auto;
        padding: 0 0 20px 0;
    }
}

.form-part {
    display: flex;
    flex-direction: column;
    height: 90vh;
    justify-content: center;

    .form-wrapper {
        margin: 0 auto;
        height: 300px;
        max-width: 480px;
        box-sizing: border-box;
        background-color: $dom-blue;
        border-radius: 16px;
        box-shadow: #00000030 1px 4px 10px 3px;

        padding: 40px;

        input {
            outline: none;
            border: none;
            width: 100%;
            height: 50px;
            box-sizing: border-box;
            padding: 15px 35px;

            border-radius: 5px;

            font-family: Arial, serif;
            font-size: 12pt;

            background-color: $dom-light-blue;
            color: white;

            &::-moz-placeholder {
                color: white;
                font-size: 80%;
            }

            &::-webkit-input-placeholder {
                color: white;
                font-size: 80%;
            }

            margin: 10px 0;

        }

        input[type=email] {
            background-color: $dom-light-blue;
        }

        input[type=submit] {
            background-color: $dom-green;

            box-shadow: $dom-dark-blue 0 2px 0 0;
            cursor: pointer;

            &:active {
                position: relative;
                top: 2px;
                box-shadow: $dom-dark-blue 0 0 0 0;
            }
        }

        .descr {
            margin: 10px 0;
            padding: 8px;
            color: $dom-text-blue;
            text-shadow: #000000ab 1px 1px 3px;
        }

    }
}

</style>
